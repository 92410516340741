import * as Rails from '@rails/ujs'
import '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'
import Trix from 'trix'
import '@rails/actiontext'
import './controllers'
import ahoy from 'ahoy.js'

Rails.start()
ActiveStorage.start()
Trix.config.blockAttributes.heading1.tagName = 'h3'

ahoy.configure({
  visitsUrl: '/profelytics/visits',
  eventsUrl: '/profelytics/events'
})
