import { Controller } from '@hotwired/stimulus'
import BulmaTagsInput from '@creativebulma/bulma-tagsinput'

export default class extends Controller {
  static targets = ['tagInput']
  connect () {
    this.tagInputSetup()
    this.listenTurboEvents()
    this.pushFooterDown()
    this.pullChatUp()
  }

  listenTurboEvents () {
    document.addEventListener('turbo:before-cache', () => {
      document.querySelector('html').classList.remove('is-clipped')
      document.querySelectorAll('div.is-active').forEach((el) => el.classList.remove('is-active'))
    })
  }

  tagInputSetup () {
    this.tagInputTargets.forEach((realInput) => {
      const tagInput = new BulmaTagsInput(realInput, { maxChars: parseInt(realInput.dataset.maxLength), minChars: 2 })
      tagInput.input.onblur = function () {
        tagInput.add(tagInput.input.value)
        tagInput.input.value = ''
      }
    })
  }

  pushFooterDown () {
    if (document.querySelectorAll('main.container').length === 0) { return }

    const footerHeight = document.querySelector('footer#page-footer').clientHeight
    document.querySelector('main.container').style.minHeight = `calc(100vh - 6.5rem - ${footerHeight}px)`
  }

  pullChatUp () {
    setTimeout(() => {
      document.querySelectorAll("iframe[height='64px']").forEach((iframe) => {
        iframe.style.marginBottom = '50px'
      })
    }, 5000)
  }
}
