import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tab', 'tabPanel']
  static values = {
    visibleIndex: { type: Number, default: 0 }
  }

  changeTab (event) {
    this.visibleIndexValue = event.params.tabIndex
  }

  visibleIndexValueChanged () {
    this.tabPanelTargets.forEach((tabPanel, index) => {
      if (index === this.visibleIndexValue) {
        this.tabTargets[index].ariaSelected = true
        this.tabTargets[index].classList.add('is-active')
        tabPanel.classList.remove('is-hidden')
      } else {
        this.tabTargets[index].ariaSelected = false
        this.tabTargets[index].classList.remove('is-active')
        tabPanel.classList.add('is-hidden')
      }
    })
  }
}
