import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggeable']

  toggle (event) {
    this.hiddenClass = this.toggeableTarget.classList.value.match(/is-hidden\S*/) || 'is-hidden'
    this.toggeableTarget.classList.toggle(this.hiddenClass)
  }
}
