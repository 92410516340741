import { Controller } from '@hotwired/stimulus'
import { currentUser, setTimeAgo } from '../utils'

export default class extends Controller {
  static targets = ['date', 'editAction']

  connect () {
    setTimeAgo(this.dateTarget)
    this.setActions()
  }

  setActions () {
    if (this.data.get('owner') === currentUser()) {
      this.editActionTarget.classList.remove('is-hidden')
    }
  }
}
