import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggle', 'menu']

  toggle () {
    this.toggleTarget.classList.toggle('is-active')
    this.menuTarget.classList.toggle('is-active')
  }
}
