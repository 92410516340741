import { Controller } from '@hotwired/stimulus'
// TODO: remove the modals_controller after migrating all modals to turbo.
export default class extends Controller {
  connect () {
    document.querySelector('html').classList.add('is-clipped')
    this.element.focus()
  }

  disconnect () {
    document.querySelector('html').classList.remove('is-clipped')
    this.modalTurboFrame.src = null
  }

  close () {
    this.element.remove()
  }

  get modalTurboFrame () {
    return document.querySelector("turbo-frame[id='generic-modal']")
  }
}
