import { Controller } from '@hotwired/stimulus'
import { openModal } from '../utils'
import { post, get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['dismiss']

  connect () {
    this.openPopupModal()
    this.trackDismissClicks()
    this.dismissBannerOnVisit()
  }

  disconnect () {
    if (this.timeout) { clearTimeout(this.timeout) }
  }

  async openPopupModal () {
    if (this.element.classList.contains('popup-banner')) {
      this.timeout = setTimeout(() => {
        openModal(this.element)
        const properties = {
          name: 'show',
          banner_id: this.dismissTarget.dataset.id
        }

        post('/banner_events', { body: JSON.stringify({ banner_event: properties }) })
      }, 8000)
    }
  }

  async dismissBannerOnVisit () {
    this.element.querySelectorAll('a:not(.frame-link)').forEach(anchor => {
      if (!this.element.classList.contains('sidebar-banner')) {
        anchor.dataset.turboFrame = '_top'
      }
      anchor.addEventListener('click', async (event) => {
        event.preventDefault()

        const properties = {
          name: 'visit',
          banner_id: this.dismissTarget.dataset.id
        }
        await post('/banner_events', { body: JSON.stringify({ banner_event: properties }) })
        if (!this.element.classList.contains('sidebar-banner')) {
          await get('/banners/dismiss')
        }
        location.assign(anchor.href)
      })
    })
  }

  async trackDismissClicks () {
    if (!this.hasDismissTarget) {
      return
    }

    this.dismissTargets.forEach((target) => {
      target.addEventListener('click', () => {
        const properties = {
          name: 'dismiss',
          banner_id: target.dataset.id
        }

        post('/banner_events', { body: JSON.stringify({ banner_event: properties }) })
        const feedTabs = document.getElementById('feed-tabs')
        if (feedTabs) { feedTabs.controller.recalculatePosition(true) }
      })
    })
  }
}
