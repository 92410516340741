import { Controller } from '@hotwired/stimulus'
import { likeLikeable } from '../utils'

export default class extends Controller {
  static targets = ['likeButton']

  connect () {
    if (this.hasLikeButtonTarget) { this.originalLikeButton = this.likeButtonTarget.innerHTML }
  }

  like () {
    if (this.liked) {
      this.likeButtonTarget.innerHTML = this.originalLikeButton
      this.liked = false
    } else {
      this.likeButtonTarget.innerHTML += '<span class="icon is-small ml-2"><i class="fas fa-check"></i></span>'
      this.liked = true
    }

    const url = `/posts/${this.data.get('id')}/like`
    likeLikeable(url, this.postController)
  }

  directDownload (event) {
    const target = event.currentTarget
    const postId = target.dataset.postId
    fetch(`/download/${postId}/download_and_follow`).then(response => {
      if (response.ok) {
        const downloadEvent = new CustomEvent('downloaded', {
          bubbles: true,
          cancelable: true,
          detail: {}
        })
        target.dispatchEvent(downloadEvent)
      }
    })
  }

  get postController () {
    const element = document.getElementById(`post-${this.data.get('id')}`)
    if (element !== null) {
      return element.controller
    } else {
      return null
    }
  }
}
