import { Controller } from '@hotwired/stimulus'
import * as Mailcheck from 'mailcheck'
import { suggest } from '../utils'

export default class extends Controller {
  static targets = ['email', 'emailSuggestion']

  validateEmail (event) {
    Mailcheck.run({
      email: this.emailTarget.value,
      topLevelDomains: [],
      suggested: (suggestion) => {
        this.emailSuggestionTarget.innerHTML = `${window.I18n.maybe_email} ${suggest(suggestion.full)}`
        this.emailSuggestionTarget.classList.remove('is-hidden')
      },
      empty: () => {
        this.emailSuggestionTarget.classList.add('is-hidden')
      }
    })
  }
}
