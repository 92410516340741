import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['sendMessageForm', 'sendMessageInput']
  static values = { autofocus: Boolean }

  connect () {
    this.sendMessageListener()
    if (this.autofocusValue) { this.sendMessageInputTarget.focus() }
    this.canSend = true
  }

  sendMessageListener () {
    const $this = this
    this.sendMessageFormTarget.addEventListener('keydown', function (e) {
      if (e.key === 'Enter' && !e.shiftKey && $this.canSend) {
        $this.canSend = false
        e.preventDefault()

        // eslint-disable-next-line no-undef
        Turbo.navigator.submitForm($this.sendMessageFormTarget)
        const conversationContainer = document.getElementById('chat-messages-container')
        conversationContainer.scrollTop = conversationContainer.scrollHeight
      }
    })
  }
}
