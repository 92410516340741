import { Controller } from '@hotwired/stimulus'
import Swiper, { Pagination, Navigation } from 'swiper'

export default class extends Controller {
  static targets = [
    'container', 'wrapper', 'pagination', 'prev', 'next'
  ]

  connect () {
    this.element.controller = this
    this.swiper = this.setSwiper()
  }

  setSwiper () {
    const pagination = this.hasPaginationTarget ? this.paginationTarget : null
    const prev = this.hasPrevTarget ? this.prevTarget : null
    const next = this.hasNextTarget ? this.nextTarget : null

    const opts = { modules: [Navigation, Pagination] }
    if (pagination) {
      opts.pagination = { el: `#${pagination.id}`, clickable: true }
      opts.navigation = {
        prevEl: `#${prev.id}`,
        nextEl: `#${next.id}`
      }
    }
    if (this.data.get('perPage')) {
      const perPage = parseInt(this.data.get('perPage'))
      const perPageMobile = parseInt(this.data.get('perPageMobile')) || 1
      opts.slidesPerView = perPageMobile
      opts.slidesPerGroup = perPageMobile
      opts.spaceBetween = 50
      opts.breakpoints = {
        769: { slidesPerView: perPage, slidesPerGroup: perPage }
      }
    }
    return new Swiper(`#${this.containerTarget.id}`, opts)
  }
}
