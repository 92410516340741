import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['conversationContainer']

  getConversationsAndMessagesElements () {
    return [
      document.getElementById('conversation-section'),
      document.getElementById('messages-section')
    ]
  }

  connect () {
    this.element.controller = this
  }

  showMessages () {
    const [conversationsSection, messagesSection] = this.getConversationsAndMessagesElements()

    conversationsSection.classList.add('is-hidden-mobile')
    messagesSection.classList.remove('is-hidden-mobile')
  }

  showConversations () {
    const [conversationsSection, messagesSection] = this.getConversationsAndMessagesElements()

    conversationsSection.classList.remove('is-hidden-mobile')
    messagesSection.classList.add('is-hidden-mobile')
  }

  scrollToBottom () {
    this.conversationContainerTarget.scrollTop = this.conversationContainerTarget.scrollHeight
  }
}
