import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  applySuggestion (event) {
    const control = event.currentTarget.closest('.control')
    const input = control.querySelector('input')
    input.value = event.currentTarget.dataset.suggestion
    event.currentTarget.closest('.help').classList.add('is-hidden')
  }
}
