import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['card', 'next', 'action']
  connect () {
    this.active_count = 0
    this.showNextCard()
  }

  showNextCard () {
    // Hide skipped element
    const currentIndex = this.cardTargets.findIndex(el => el.dataset.cardId === this.lastShowed)
    if (currentIndex > -1) {
      this.cardTargets[currentIndex].classList.toggle('is-hidden')
    }

    // Reveal target element
    let targetIndex
    if (this.nextCard) {
      targetIndex = this.cardTargets.findIndex(el => el.dataset.cardId === this.nextCard)
    } else {
      targetIndex = (currentIndex + 1) % this.cardTargets.length
    }

    this.cardTargets[targetIndex].classList.remove('is-hidden')
    this.lastShowed = this.cardTargets[targetIndex].dataset.cardId

    // Store the next element to be revealed in case the current is eliminated
    const nextIndex = (targetIndex + 1) % this.cardTargets.length
    this.nextCard = this.cardTargets[nextIndex].dataset.cardId

    this.checkMinRequired()
  }

  checkMinRequired () {
    const minRequired = Number(this.nextTarget.dataset.min)
    this.nextTarget.disabled = this.active_count < minRequired
    if (this.hasActionTarget) { this.countDown(minRequired) }
  }

  countDown (minRequired) {
    let message
    const activationsLeft = minRequired - this.active_count
    if (activationsLeft > 0) {
      message = this.actionTarget.dataset.message.replace('{count}', activationsLeft.toString())
    } else {
      message = this.actionTarget.dataset.message.split('.')[1].trim()
    }

    this.actionTarget.textContent = message
  }

  activateCard (event) {
    this.active_count += 1
    this.showNextCard()
    // Fire toast event
    const toastEvent = new CustomEvent('toast', {
      bubbles: true,
      cancelable: true,
      detail: { toast: { message: event.currentTarget.dataset.message } }
    })

    event.currentTarget.dispatchEvent(toastEvent)
    event.currentTarget.parentElement.removeChild(event.currentTarget)
  }
}
