import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tagInput']

  emailsInputChanged (event) {
    if (event.currentTarget.value.endsWith(' ')) {
      event.currentTarget.blur()
      this.tagInputTarget.focus()
    }
  }
}
