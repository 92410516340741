import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['file', 'submitButton']

  clickFile (event) {
    event.preventDefault()
    this.fileTarget.click()
  }

  submit () {
    this.submitButtonTarget.click()
  }
}
