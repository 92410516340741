import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'input', 'sortBy', 'filter', 'filtersAppliedCount',
    'minAgeFilter', 'maxAgeFilter', 'filtersWrapper', 'filterSearch'
  ]

  connect () {
    this.searchTerm = this.initializeTerm()
    this.countFilters()
  }

  checkIfSubmitWithEnter (event) {
    if (event.key === 'Enter') {
      this.search()
    } else if (event.key === 'ArrowDown') {
      this.focusSuggestion(this.firstSuggestion(event))
      event.preventDefault()
    }
  }

  firstSuggestion (event) {
    return event.currentTarget.closest('form').querySelector('.dropdown-item:first-child')
  }

  lastSuggestion (event) {
    return event.currentTarget.closest('form').querySelector('.dropdown-item:last-child')
  }

  nextSuggestion (currentTarget) {
    const nextTarget = currentTarget.nextElementSibling
    if (nextTarget.classList.contains('suggestion')) {
      return nextTarget
    } else {
      return this.nextSuggestion(nextTarget)
    }
  }

  previousSuggestion (currentTarget) {
    const previousTarget = currentTarget.previousElementSibling
    if (previousTarget.classList.contains('suggestion')) {
      return previousTarget
    } else {
      return this.previousSuggestion(previousTarget)
    }
  }

  focusSuggestion (suggestion) {
    if (suggestion) { suggestion.focus() }
  }

  navigateSuggestions (event) {
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      if (event.currentTarget !== this.lastSuggestion(event)) {
        this.nextSuggestion(event.currentTarget).focus()
      } else {
        this.focusSuggestion(this.firstSuggestion(event))
      }
    } else if (event.key === 'ArrowUp') {
      event.preventDefault()
      if (event.currentTarget !== this.firstSuggestion(event)) {
        this.previousSuggestion(event.currentTarget).focus()
      } else {
        event.currentTarget.closest('form').querySelector('input').focus()
      }
    } else if (event.key === 'Enter' && event.currentTarget.dataset.scope === 'search_term') {
      this.doSearch(event)
    }
  }

  typeTerm (event) {
    this.searchTerm = event.currentTarget.value
    const trigger = event.currentTarget.nextElementSibling
    if (this.suggestionTimeout) { clearTimeout(this.suggestionTimeout) }
    this.suggestionTimeout = setTimeout(() => { trigger.click() }, 300)
  }

  search () {
    /* eslint-disable no-undef */
    Turbo.clearCache()
    Turbo.visit(`${this.searchScope()}/search?q=${this.searchTerm}${this.sortBy()}${this.filter()}`)
    /* eslint-enable no-undef */
  }

  doSearch (event) {
    event.preventDefault()
    /* eslint-disable no-undef */
    Turbo.visit(`/search?q=${event.currentTarget.dataset.term}${this.sortBy()}${this.filter()}`)
    /* eslint-enable no-undef */
  }

  searchScope () {
    let scope = ''
    if (window.location.href.indexOf('users/search') !== -1) {
      scope = '/users'
    }
    return scope
  }

  sortBy () {
    if (!this.hasSortByTarget) { return '' }

    return `&sort_by=${this.sortByTarget.value}`
  }

  filter () {
    if (!this.hasFilterTarget) {
      return ''
    }

    const checkboxFilters = this.filterTargets.map((filter) => {
      return filter.checked ? `${filter.name}[]=${filter.value}` : null
    })

    const filters = [...this.ageFilters(), ...checkboxFilters].filter((obj) => obj).join('&')
    return filters !== '' ? `&${filters}` : ''
  }

  initializeTerm () {
    const input = this.inputTargets.find((input) => input.value !== '')
    return (input && input.value) || ''
  }

  countFilters () {
    if (!this.hasFiltersAppliedCountTarget) { return }

    let count = this.filterTargets.filter((filter) => filter.checked).length
    if (this.minAgeFilterTarget.value !== '') { count += 1 }
    if (this.maxAgeFilterTarget.value !== '') { count += 1 }

    this.filtersAppliedCountTarget.innerText = count
  }

  toggleFilter (event) {
    this.showSearch()
  }

  ageFilters () {
    const filter = []
    if (this.minAgeFilterTarget.value !== '') {
      filter.push(`filters[min_age]=${this.minAgeFilterTarget.value}`)
    }
    if (this.maxAgeFilterTarget.value !== '') {
      filter.push(`filters[max_age]=${this.maxAgeFilterTarget.value}`)
    }
    return filter
  }

  changeAge (event) {
    this.showSearch()
  }

  showSearch () {
    this.countFilters()
    this.filterSearchTarget.classList.remove('is-hidden')
  }

  toggleFilters () {
    this.filtersWrapperTarget.classList.toggle('is-hidden-mobile')
  }
}
