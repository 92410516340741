import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['field']

  fieldTargetConnected (field) {
    const input = field.querySelector('input, select, textarea')
    this.setFloatingLabel(input)
    input.addEventListener('input', () => {
      this.setFloatingLabel(input)
    })
  }

  setFloatingLabel (input) {
    if ((input.value.length || input.type === 'date') && this.element.querySelector(`label[for='${input.id}']`) === null) {
      const label = this.createLabel(input)
      input.parentNode.parentNode.insertBefore(label, input.parentNode)
    } else if (input.value.length === 0 && this.element.querySelector(`label[for='${input.id}']`) !== null) {
      this.element.querySelector(`label[for='${input.id}']`).remove()
    }
  }

  createLabel (input) {
    const label = document.createElement('label')
    label.setAttribute('for', input.id)
    label.innerText = input.dataset.placeholder || input.placeholder
    label.classList.add('floating-label')
    return label
  }
}
