import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['configureButton']

  connect () {
    this.openConfiguration()
  }

  openConfiguration () {
    if (this.configureButtonTarget.dataset.configure) {
      this.configureButtonTarget.click()
    }
  }
}
