import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['quantity', 'total']

  connect () {
    this.setPaypal()
  }

  processPayment (event) {
    this.priceUsd = parseFloat(event.params.priceUsd).toFixed(2)
    this.quantity = parseInt(event.params.quantity)
    const paypalWrapper = document.getElementById('paypal-button-container')
    paypalWrapper.innerHTML = ''
    event.currentTarget.appendChild(paypalWrapper)
    this.setPaypal()
    document.getElementById('paypal-button-container').classList.remove('is-hidden')
  }

  setPaypal () {
    const $this = this
    // eslint-disable-next-line no-undef
    paypal.Buttons({
      createOrder: function (data, actions) {
        return actions.order.create({
          payer: {
            name: {
              given_name: $this.firstName,
              surname: $this.lastName
            },
            email_address: $this.emailAddress
          },
          purchase_units: [{
            amount: {
              value: $this.priceUsd,
              currency_code: 'USD',
              breakdown: {
                item_total: {
                  value: $this.priceUsd,
                  currency_code: 'USD'
                }
              }
            },
            items: [
              {
                name: `x ${$this.quantity} ProfeCoins`,
                category: 'DIGITAL_GOODS',
                quantity: '1',
                unit_amount: {
                  value: $this.priceUsd,
                  currency_code: 'USD'
                }
              }
            ]
          }],
          application_context: {
            shipping_preference: 'NO_SHIPPING'
          }
        })
      },
      onApprove: function (data, actions) {
        document.querySelector('#packs-wrapper').classList.add('is-hidden')
        document.querySelector('#loading-wrapper').classList.remove('is-hidden')
        return actions.order.capture().then(function (details) {
          // eslint-disable-next-line no-undef
          Turbo.visit(`/payments/paypal_success?trx_id=${details.id}&quantity=${$this.quantity}`)
        })
      }
    }).render('#paypal-button-container')
    // This function displays Smart Payment Buttons on your web page.
  }

  get firstName () {
    return window.currentUser.firstName
  }

  get lastName () {
    return window.currentUser.lastName
  }

  get emailAddress () {
    return window.currentUser.emailAddress
  }
}
