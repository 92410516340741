import { Controller } from '@hotwired/stimulus'
import { setTimeAgo } from '../utils'

export default class extends Controller {
  static targets = ['date']

  connect () {
    if (this.dateTarget.dataset.timestamp.length > 0) {
      setTimeAgo(this.dateTarget)
    }
  }
}
