import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['turboFrameTag']
  static values = { url: String }

  mouseEnter () {
    this.timeout = setTimeout(() => {
      this.turboFrameTagTarget.src = this.urlValue
    }, 400)
  }

  mouseLeave () {
    clearTimeout(this.timeout)
  }
}
