import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['editor', 'counter', 'counterWrapper']

  static values = {
    minLength: Number
  }

  connect () {
    this.addTrixListener()
  }

  trixUpdated () {
    const string = this.editorTarget.editor.getDocument().toString().replace(/\s+/g, ' ').trim()
    let needed = this.minLengthValue - string.length
    needed = needed < 0 ? 0 : needed
    this.counterTarget.innerText = needed
    if (needed === 0) {
      this.counterWrapperTarget.classList.add('is-hidden')
      this.editorTarget.dataset.valid = 'true'
    } else {
      this.counterWrapperTarget.classList.remove('is-hidden')
      this.editorTarget.dataset.valid = 'false'
    }
    this.element.closest('form').formController.propagateTrixEditorchange()
  }

  addTrixListener () {
    this.trixListener = addEventListener('trix-change', this.trixUpdated.bind(this))
    this.trixUpdated()
  }
}
