import { Controller } from '@hotwired/stimulus'
import {
  currentUser,
  likeLikeable,
  loggedIn,
  openModal,
  setLiked,
  setTimeAgo
} from '../utils'
import * as Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'date', 'likeHeart', 'likesCount', 'actionsEllipsis', 'likeHeartWrapper',
    'deleteAction', 'content', 'commentBody'
  ]

  connect () {
    setTimeAgo(this.dateTarget)
    setLiked(this.data.get('likedBy'), this.likeHeartTarget)
    this.setMentionsLinks()
    this.setActions()
  }

  setActions () {
    if (this.data.get('owner') === currentUser()) {
      this.deleteActionTarget.classList.remove('is-hidden')
    }
  }

  like (event) {
    event.stopPropagation()
    if (!loggedIn()) {
      openModal('needs-login-modal')
      return
    }

    const url = `/comments/${this.data.get('id')}/like`
    likeLikeable(url, this)
  }

  toggleActions (event) {
    if (event.target.tagName === 'A' || event.target.tagName === 'BUTTON') { return }

    if (!loggedIn()) {
      openModal('needs-login-modal')
    } else {
      this.commentBodyTarget.classList.toggle('showing-actions')
      this.likeHeartWrapperTarget.classList.toggle('is-hidden')
      this.actionsEllipsisTarget.classList.toggle('is-sr-only')
    }
  }

  setMentionsLinks () {
    const mentions = [...new Set(this.contentTarget.innerHTML.match(window.usernameRegex))]
    mentions.forEach((username) => {
      this.contentTarget.innerHTML = this.contentTarget.innerHTML.replaceAll(username, this.usernameLink(username))
    })
  }

  usernameLink (username) {
    return `<a href="/users/${username.replace('@', '')}" data-turbo-frame="_top">${username}</a>`
  }

  delete () {
    Rails.ajax({
      type: 'delete',
      url: `/comments/${this.data.get('id')}`,
      success: (data) => {
        this.element.remove()
      },
      error: (data) => { console.log(data) }
    })
  }
}
