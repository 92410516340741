import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  open (event) {
    if (event.target.tagName.toLowerCase() === 'a') {
      return
    }

    // eslint-disable-next-line no-undef
    Turbo.visit(event.currentTarget.dataset.href)
  }
}
