import { Controller } from '@hotwired/stimulus'
import * as Rails from '@rails/ujs'
import { csrfTokenHeader } from '../utils'
import consumer from '../channels/consumer'

export default class extends Controller {
  static targets = [
    'previewImage', 'previewInstructions', 'fileInput', 'uploadStateOff',
    'uploadStateOn', 'sizeErrorMessage', 'previewMetadata', 'errorWrapper',
    'errorContent', 'swiper', 'swiperControls', 'fileContainer'
  ]

  connect () {
    this.subscribeToSocket()
    this.uploadEnabled = true
  }

  selectFile (event) {
    if (event.target.classList.contains('swiper-button')) { return }

    if (this.uploadEnabled) {
      this.fileInputTarget.value = ''
      this.fileInputTarget.click()
    }
  }

  uploadFile () {
    this.uploadStateOffTarget.classList.add('is-hidden')
    this.previewImageTarget.classList.add('is-hidden')
    this.previewInstructionsTarget.classList.remove('is-hidden')
    this.errorWrapperTarget.classList.add('is-hidden')
    if (!this.preValidateFile()) {
      return
    }

    this.uploadEnabled = false
    this.uploadStateOnTarget.classList.remove('is-hidden')

    Rails.ajax({
      type: 'post',
      headers: csrfTokenHeader(),
      url: `/posts/${this.data.get('id')}/upload`,
      data: this.formData,
      success: this.uploadSuccessCallback.bind(this),
      error: this.uploadErrorCallback.bind(this)
    })
  }

  preValidateFile () {
    if (this.fileInputTarget.files[0].size > 50000000) { // 50 MB
      this.sizeErrorMessageTarget.classList.remove('is-hidden')
      return false
    } else {
      this.sizeErrorMessageTarget.classList.add('is-hidden')
      return true
    }
  }

  uploadSuccessCallback (response) {
    // nothing
  }

  uploadFinishedCallback (response) {
    this.uploadStateOnTarget.classList.add('is-hidden')
    this.uploadStateOffTarget.classList.remove('is-hidden')
    this.previewImageTarget.classList.remove('is-hidden')
    if (response.preview_files.length > 1) {
      this.swiperTarget.controller.wrapperTarget.innerHTML = ''
      response.preview_files.forEach((file) => {
        this.swiperTarget.controller.wrapperTarget.insertAdjacentHTML('beforeend', file.element)
      })
      this.swiperTarget.controller.swiper.destroy()
      this.swiperTarget.controller.setSwiper()
      this.swiperControlsTarget.classList.remove('is-hidden')
      this.swiperTarget.controller.containerTarget.classList.remove('is-hidden')
      this.fileContainerTarget.classList.add('is-hidden')
    } else {
      this.fileContainerTarget.innerHTML = response.preview_files[0].element
      this.swiperControlsTarget.classList.add('is-hidden')
      this.swiperTarget.controller.containerTarget.classList.add('is-hidden')
      this.fileContainerTarget.classList.remove('is-hidden')
    }

    this.previewMetadataTarget.textContent = response.metadata
    this.previewInstructionsTarget.classList.add('is-hidden')
    this.uploadEnabled = true
  }

  uploadErrorCallback (response) {
    this.uploadStateOnTarget.classList.add('is-hidden')
    this.errorWrapperTarget.classList.remove('is-hidden')
    this.errorContentTarget.textContent = response.error
    this.uploadEnabled = true
  }

  subscribeToSocket () {
    const $this = this
    consumer.subscriptions.create(
      { channel: 'UploadStatusChannel', post_id: $this.data.get('id') },
      {
        received (data) {
          if (data.uploaded) {
            Rails.ajax({
              type: 'get',
              url: `/posts/${$this.data.get('id')}/files`,
              success: $this.uploadFinishedCallback.bind($this),
              error: (data) => { console.log(data) }
            })
          } else {
            $this.uploadErrorCallback(data)
          }
        }
      }
    )
  }

  get formData () {
    const form = new FormData()
    form.append('post[file]', this.fileInputTarget.files[0])
    return form
  }
}
