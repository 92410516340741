import { Controller } from '@hotwired/stimulus'
import {
  likeLikeable, loggedIn, openModal, setLiked,
  setTimeAgo, currentUser
} from '../utils'
import * as Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'date', 'likeHeart', 'likesCount', 'deleteAction', 'editAction', 'featureAction', 'confirmDeleteModal',
    'description', 'showMoreButton', 'commentInput'
  ]

  connect () {
    this.element.controller = this
    setTimeAgo(this.dateTarget)
    this.setReadMore()
    if (loggedIn() && document.querySelector('#posts') !== null) { this.setScrollListener() }
    this.overrideExternalLinks()
    setLiked(this.data.get('likedBy'), this.likeHeartTarget)
    this.setActions()
    this.setDownloadLinkParams()
  }

  disconnect () {
    if (this.listener !== undefined) {
      window.removeEventListener('scroll', this.listener, false)
    }
  }

  setScrollListener () {
    const $this = this
    this.listener = function () {
      if ($this.isNear($this.element)) {
        document.querySelector('#posts').controller.pushId($this.data.get('id'))
        window.removeEventListener('scroll', $this.listener, false)
      }
    }
    window.addEventListener('scroll', this.listener, false)
  }

  setDownloadLinkParams () {
    const button = this.element.querySelector('.post__content .post__media .button.is-primary.is-fullwidth')
    if (button === null) { return }

    const searchTerm = new URLSearchParams(window.location.search).get('q')
    const source = this.getSourceOfDownload()
    const params = new URLSearchParams('')
    if (searchTerm) {
      params.append('search_term', searchTerm)
    }
    if (source) {
      params.append('source', source)
    }
    const extraPath = currentUser() ? '' : '/show_guest'
    button.href = button.href.split('?')[0].concat(`${extraPath}?${params.toString()}`)
  }

  getSourceOfDownload () {
    if (window.location.href.includes('chronological')) {
      return 'recent'
    } else if (window.location.href.includes('feed')) {
      return 'popular'
    } else if (window.location.href.includes('recommended')) {
      return 'recommended'
    } else if (window.location.href.includes('users')) {
      return 'profile'
    } else if (window.location.href.includes('categories')) {
      return 'categories'
    } else if (window.location.href.includes('search')) {
      return 'search'
    } else if (window.location.href.includes('posts')) {
      return 'individual'
    }

    return undefined
  }

  isNear (elem) {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const scrollHeight = document.documentElement.scrollHeight
    const windowHeight = window.innerHeight || document.documentElement.clientHeight
    const elOffset = elem.offsetTop
    // scroll bar is near the element or scroll bar is at the bottom
    return (scrollTop > elOffset) || (windowHeight + scrollTop) === scrollHeight
  }

  setActions () {
    if (this.data.get('owner') === currentUser()) {
      this.deleteActionTarget.classList.remove('is-hidden')
      this.editActionTarget.classList.remove('is-hidden')
      this.featureActionTarget.classList.remove('is-hidden')
    }
  }

  overrideExternalLinks () {
    // Open all external links in a new window
    this.element.querySelectorAll('a').forEach((el) => {
      if (el.href.indexOf(location.hostname) === -1) {
        el.setAttribute('target', '_blank')
        el.setAttribute('rel', 'noopener ugc nofollow')
        if (el.innerText.length > 50) {
          el.innerText = el.innerText.substr(0, 47) + '...'
        }
      }
    })
  }

  scrollIntoComment () {
    this.commentInputTarget.focus({ preventScroll: false })
  }

  like (event) {
    event.stopPropagation()
    if (!loggedIn()) {
      openModal('needs-login-modal')
      return
    }

    const url = `/posts/${this.data.get('id')}/like`
    likeLikeable(url, this)
  }

  confirmDelete () {
    if (this.hasConfirmDeleteModalTarget) {
      openModal(`confirm-delete-modal-${this.data.get('id')}`)
      return
    }

    Rails.ajax({
      type: 'get',
      url: `/posts/${this.data.get('id')}/confirm_delete_modal`,
      success: (data) => {
        this.element.insertAdjacentHTML('beforeend', data)
        openModal(`confirm-delete-modal-${this.data.get('id')}`)
      },
      error: (data) => { console.log(data) }
    })
  }

  delete (event) {
    event.currentTarget.closest('.modal').classList.toggle('is-active')
    document.querySelector('html').classList.toggle('is-clipped')

    Rails.ajax({
      type: 'delete',
      url: `/posts/${this.data.get('id')}`,
      success: (data) => {
        this.element.remove()
      },
      error: (data) => { console.log(data) }
    })
  }

  setReadMore () {
    if (window.location.href.indexOf('posts') !== -1) { return } // do not show the read more in the show view

    if (this.descriptionTarget.offsetHeight > 270) {
      this.shrinkContent()
      this.descriptionTarget.insertAdjacentHTML('afterend', this.buildReadMoreButton())
    }
  }

  showMoreText () {
    if (this.showMoreButtonTarget.innerText === window.I18n.read_less) {
      this.showMoreButtonTarget.innerText = window.I18n.read_more
      this.shrinkContent()
    } else {
      this.enlargeContent()
      this.showMoreButtonTarget.innerText = window.I18n.read_less
    }
  }

  buildReadMoreButton () {
    const buttonClass = 'button is-white unlink has-text-semibold mt-1'
    const buttonData = 'data-action="post#showMoreText" data-post-target="showMoreButton"'
    return `<div class="has-text-right"><button ${buttonData} class="${buttonClass}">${window.I18n.read_more}</button></div>`
  }

  shrinkContent () {
    this.descriptionTarget.style.maxHeight = '270px'
    this.descriptionTarget.style.overflowY = 'hidden'
  }

  enlargeContent () {
    this.descriptionTarget.style.maxHeight = 'unset'
  }
}
