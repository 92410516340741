import { Controller } from '@hotwired/stimulus'
import { toast } from 'bulma-toast'

export default class extends Controller {
  static values = {
    message: String,
    position: String,
    type: String
  }

  show (event) {
    if (!event.detail.toast) {
      return
    }

    toast({
      message: this.messageValue,
      position: this.positionValue || 'top-center',
      type: this.typeValue || 'is-success',
      dismissible: true,
      animate: { in: 'fadeIn', out: 'fadeOut' },
      ...event.detail.toast
    })
  }
}
