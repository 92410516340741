import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.element.controller = this
    this.recalculatePosition(false)
  }

  recalculatePosition (forceDefault) {
    const banners = document.getElementsByClassName('banner')
    if (banners.length > 0 && !forceDefault) {
      this.element.style.top = `calc(3.5rem + ${banners[0].clientHeight}px)`
    } else {
      this.element.style.top = '3.5rem'
    }
  }
}
