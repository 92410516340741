import { Controller } from '@hotwired/stimulus'
import { currentUser } from '../utils'

export default class extends Controller {
  static targets = ['messageDeleteAction']

  connect () {
    this.setActions()
  }

  setActions () {
    if (this.data.get('sender') === currentUser()) {
      this.messageDeleteActionTarget.classList.remove('is-hidden')
    }
  }
}
