import { Controller } from '@hotwired/stimulus'
import { loggedIn } from '../utils'

// Connects to data-controller='landing-post'
export default class extends Controller {
  static targets = ['description', 'showMoreButton', 'downloadButton', 'registerAndDownloadButton']
  connect () {
    this.setReadMore()
    this.showCorrectCTAButton()
  }

  showCorrectCTAButton () {
    if (loggedIn()) {
      this.downloadButtonTarget.classList.remove('is-hidden')
    } else {
      this.registerAndDownloadButtonTarget.classList.remove('is-hidden')
    }
  }

  setReadMore () {
    if (this.descriptionTarget.offsetHeight > 270) {
      this.shrinkContent()
      this.descriptionTarget.insertAdjacentHTML('afterend', this.buildReadMoreButton())
    }
  }

  showMoreText () {
    if (this.showMoreButtonTarget.innerText === window.I18n.read_less) {
      this.showMoreButtonTarget.innerText = window.I18n.read_more
      this.shrinkContent()
    } else {
      this.enlargeContent()
      this.showMoreButtonTarget.innerText = window.I18n.read_less
    }
  }

  buildReadMoreButton () {
    const buttonClass = 'button is-white unlink has-text-semibold mt-1'
    const buttonData = 'data-action="landing-post#showMoreText" data-landing-post-target="showMoreButton"'
    return `<div class="has-text-right"><button ${buttonData} class="${buttonClass}">${window.I18n.read_more}</button></div>`
  }

  shrinkContent () {
    this.descriptionTarget.style.maxHeight = '270px'
    this.descriptionTarget.style.overflowY = 'hidden'
  }

  enlargeContent () {
    this.descriptionTarget.style.maxHeight = 'unset'
  }
}
