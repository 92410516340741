import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="payout"
export default class extends Controller {
  static targets = [
    'documentTypeField', 'documentNumberHelp', 'documentNumberHelpValue', 'openModalLink',
    'accountNumber', 'accountNumberHelp', 'country'
  ]

  showDocumentNumberHelp () {
    if (this.documentTypeFieldTarget.value.length) {
      this.documentNumberHelpTarget.classList.remove('is-hidden')
      this.documentNumberHelpValueTarget.innerText = this.documentTypeFieldTarget.value
    } else {
      this.documentNumberHelpTarget.classList.add('is-hidden')
    }
  }

  countryChanged () {
    if (this.countryTarget.value === 'MX') {
      this.accountNumberHelpTarget.innerText = window.I18n.payouts.new.mx_account_number_hint
      this.accountNumberTarget.pattern = '\\d{18}'
      this.accountNumberHelpTarget.classList.remove('is-hidden')
    } else {
      this.accountNumberHelpTarget.classList.add('is-hidden')
      this.accountNumberTarget.removeAttribute('pattern')
    }
  }
}
