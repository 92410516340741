import { Controller } from '@hotwired/stimulus'
import { setTimeAgo } from '../utils'

export default class extends Controller {
  static targets = ['date']

  connect () {
    setTimeAgo(this.dateTarget)
  }

  redirectToPost () {
    // eslint-disable-next-line no-undef
    Turbo.visit(this.data.get('href'))
  }
}
