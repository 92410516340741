import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['coinPrice', 'priceReference']

  connect () {
    this.dollarFactor = parseFloat(this.data.get('dollar-factor'))
    this.currency = this.data.get('currency')
    this.locale = window.currentLocale
    this.formatter = new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: this.currency,
      currencyDisplay: 'name'
    })
    this.recalculatePriceReference()
  }

  recalculatePriceReference () {
    setTimeout(() => {
      this.coinPrice = parseInt(this.coinPriceTarget.value)
      if (isNaN(this.coinPrice) || this.coinPrice === 0) {
        this.priceReferenceTarget.innerText = window.I18n.zero_means_free
      } else if (this.coinPrice < 5) {
        this.forceMinCoins()
      } else {
        const finalPrice = this.price() * this.dollarFactor
        this.priceReferenceTarget.innerText = `${window.I18n.equivalent_to} ${this.formatter.format(finalPrice)}`
      }
    }, 500)
  }

  forceMinCoins () {
    if (this.coinWasGreaterThanFive) {
      this.coinPriceTarget.value = '0'
      this.coinWasGreaterThanFive = false
    } else {
      this.coinPriceTarget.value = '5'
      this.coinWasGreaterThanFive = true
    }

    this.recalculatePriceReference()
  }

  price () {
    return 0.1 * this.coinPrice
  }
}
