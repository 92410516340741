import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js'

export default class extends Controller {
  static targets = ['content']
  connect () {
    this.tippyInstance = tippy(this.button, {
      content: this.contentTarget,
      arrow: false,
      interactive: true,
      allowHTML: true,
      placement: 'top-start',
      theme: 'light-border',
      maxWidth: '420px'
    })
    this.tippyInstance.show()
  }

  get button () {
    return this.element.parentElement.parentElement
  }
}
