import { Controller } from '@hotwired/stimulus'
import { listenClickAway } from '../utils'

export default class extends Controller {
  static targets = ['dropdownToggle']
  open (event) {
    event.stopPropagation()
    if (this.element.classList.contains('is-active')) {
      this.close()
    } else {
      this.element.classList.add('is-active')
      this.dropdownToggleTarget.setAttribute('aria-expanded', 'true')
      this.listener = listenClickAway(this.close.bind(this))
    }
  }

  close () {
    this.element.classList.remove('is-active')
    this.dropdownToggleTarget.setAttribute('aria-expanded', 'false')
    document.removeEventListener('click', this.listener)
  }

  preventClose (event) {
    event.stopPropagation()
  }
}
