import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['passwordField', 'notificationsWrapper', 'toggleNotificationSettingsButton', 'togglePasswordChangeButton']

  togglePasswordChange () {
    this.passwordFieldTargets.forEach((field) => {
      field.classList.toggle('is-hidden')
    })
    if (this.togglePasswordChangeButtonTarget.getAttribute('aria-expanded') === 'false') {
      document.getElementById('user_password').focus()
      this.togglePasswordChangeButtonTarget.setAttribute('aria-expanded', 'true')
    } else {
      this.togglePasswordChangeButtonTarget.setAttribute('aria-expanded', 'false')
    }
  }

  toggleNotificationSettings () {
    this.notificationsWrapperTarget.classList.toggle('is-hidden')
    if (this.toggleNotificationSettingsButtonTarget.getAttribute('aria-expanded') === 'false') {
      document.getElementById('dontNotifyMe').focus()
      this.toggleNotificationSettingsButtonTarget.setAttribute('aria-expanded', 'true')
    } else {
      this.toggleNotificationSettingsButtonTarget.setAttribute('aria-expanded', 'false')
    }
  }

  saveCountry (e) {
    const joinSymbol = window.location.href.indexOf('?') !== -1 ? '&' : '?'
    // eslint-disable-next-line no-undef
    Turbo.visit(`${window.location.href}${joinSymbol}country=${e.currentTarget.value}`)
  }
}
