import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['filterInput', 'listItem']

  filter () {
    this.listItemTargets.forEach((listItem) => {
      if (listItem.innerText.toLowerCase().includes(this.filterInputTarget.value.toLowerCase())) {
        listItem.classList.remove('is-hidden')
      } else {
        listItem.classList.add('is-hidden')
      }
    })
  }
}
