import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'next', 'action']

  connect () {
    this.showSelected()
    this.checkSelectedSize()
  }

  select (event) {
    const button = event.currentTarget
    button.classList.toggle('is-hovered')
    button.blur()
    this.getOption(button.dataset.pickerValue).toggleAttribute('selected')
    this.checkSelectedSize()
  }

  getOption (value) {
    return this.formTarget.querySelector(`option[value="${value}"]`)
  }

  showSelected () {
    this.formTarget.querySelectorAll('option[selected]').forEach(option => {
      const button = document.querySelector(`[data-picker-value="${option.value}"]`)
      button.classList.add('is-hovered')
    })
  }

  checkSelectedSize () {
    const minSelected = Number(this.nextTarget.dataset.minSelected)
    const selectedCount = this.formTarget.querySelectorAll('option[selected]').length
    this.nextTarget.disabled = selectedCount < minSelected
    if (this.hasActionTarget) { this.countDown(minSelected, selectedCount) }
  }

  countDown (minSelected, selectedCount) {
    let message
    const activationsLeft = minSelected - selectedCount
    if (activationsLeft > 0) {
      message = this.actionTarget.dataset.message.replace('{count}', activationsLeft.toString())
    } else {
      message = this.actionTarget.dataset.message.split('.')[1].trim()
    }

    this.actionTarget.textContent = message
  }
}
