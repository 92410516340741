import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['usedWithStudentsCheckbox', 'maxAgeInput', 'minAgeInput',
    'agesWrapper', 'ratingInput', 'star1', 'star2', 'star3', 'star4', 'star5',
    'contentLength', 'contentTextarea', 'contentError']

  connect () {
    this.toggleAges()
    this.setStartsWithValue(this.ratingInputTarget.value)
    this.setContentLength()
  }

  toggleAges () {
    if (this.usedWithStudentsCheckboxTarget.checked) {
      this.agesWrapperTarget.classList.remove('is-hidden')
      this.minAgeInputTarget.required = true
      this.maxAgeInputTarget.required = true
    } else {
      this.agesWrapperTarget.classList.add('is-hidden')
      this.minAgeInputTarget.required = false
      this.maxAgeInputTarget.required = false
      this.minAgeInputTarget.value = null
      this.maxAgeInputTarget.value = null
    }
  }

  setStars (event) {
    const value = event.currentTarget.dataset.star
    this.setStartsWithValue(value)
  }

  setStartsWithValue (value) {
    this.ratingInputTarget.value = value

    if (value != null) {
      const stars = parseInt(value)
      for (let i = 1; i <= 5; i++) {
        if (i <= stars) {
          this[`star${i}Target`].classList.add('filled-star')
          this[`star${i}Target`].querySelector('i').classList.remove('far')
          this[`star${i}Target`].querySelector('i').classList.add('fas')
        } else {
          this[`star${i}Target`].classList.remove('filled-star')
          this[`star${i}Target`].querySelector('i').classList.add('far')
          this[`star${i}Target`].querySelector('i').classList.remove('fas')
        }
      }
    }
  }

  setContentLength () {
    this.contentTextareaTarget.value = this.contentTextareaTarget.value.substring(0, 2000)
    const length = this.contentTextareaTarget.value.length
    this.contentLengthTarget.innerText = `(${length}/2000)`
    if (length < 100) {
      this.contentLengthTarget.classList.add('has-text-danger')
      this.contentLengthTarget.classList.remove('has-text-normal')
    } else {
      this.contentErrorTarget.classList.add('is-hidden')
      this.contentLengthTarget.classList.add('has-text-normal')
      this.contentLengthTarget.classList.remove('has-text-danger')
    }
  }
}
