import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fileInput', 'filename', 'image']

  change () {
    if (this.fileInputTarget.files.length > 0) {
      const file = this.fileInputTarget.files[0]
      this.imageTarget.src = URL.createObjectURL(file)
      this.updateFilename(file)
    }
  }

  updateFilename (file) {
    if (this.hasFilenameTarget) { this.filenameTarget.textContent = file.name }
  }

  selectImage () {
    this.fileInputTarget.click()
  }
}
