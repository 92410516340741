import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['unfollow']

  connect () {
    if (this.hasUnfollowTarget) {
      const event = new CustomEvent('followed', {
        bubbles: true,
        cancelable: true,
        detail: {}
      })
      this.element.dispatchEvent(event)
    }
  }
}
