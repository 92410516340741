import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = ['userPostsSearchButton']

  connect () {
    this.element.controller = this
    this.readIds = []
  }

  disconnect () {
    this.markIdsAsRead()
    this.readIds = []
  }

  pushId (id) {
    this.readIds.push(parseInt(id))
    if (this.readIds.length >= 10) {
      this.markIdsAsRead()
    }
  }

  async markIdsAsRead () {
    if (this.readIds.length === 0) { return }
    await post('/posts/read', { body: JSON.stringify({ ids: this.readIds }) })
    this.readIds = []
  }

  submitUserPostsSearch () {
    this.userPostsSearchButtonTarget.click()
  }

  clearSearch (event) {
    if (event.currentTarget.value.trim() === '') {
      this.submitUserPostsSearch()
    }
  }
}
