import LocalizedTimeAgo from './localized_time_ago'
import * as Rails from '@rails/ujs'

export function csrfTokenHeader () {
  const token = document.getElementsByName('csrf-token')[0].content
  return { 'X-CSRF-Token': token }
}

export function toggleHeart (element, liked) {
  if (liked === 'toggle') {
    element.classList.toggle('has-text-danger')
    element.classList.toggle('fas')
    element.classList.toggle('far')
    element.setAttribute('aria-pressed', !element.getAttribute('aria-pressed'))
    return
  }

  if (liked) {
    element.classList.add('has-text-danger')
    element.classList.add('fas')
    element.classList.remove('far')
    element.setAttribute('aria-pressed', 'true')
  } else {
    element.classList.remove('has-text-danger')
    element.classList.remove('fas')
    element.classList.add('far')
    element.setAttribute('aria-pressed', 'false')
  }
}

export function currentUser () {
  return document.querySelector('body').dataset.applicationCurrentUser
}

export function loggedIn () {
  return currentUser() !== ''
}

export function setLiked (likedBy, heartTarget) {
  const currUser = currentUser()
  if (currUser !== '') {
    if (likedBy.split(',').find((u) => u === currUser) !== undefined) {
      toggleHeart(heartTarget, true)
    }
  }
}

export function setTimeAgo (element) {
  element.innerText = LocalizedTimeAgo.format(element.dataset.timestamp)
}

export function likeLikeable (url, controller) {
  if (controller !== null) { toggleHeart(controller.likeHeartTarget, 'toggle') } // anticipate draw
  Rails.ajax({
    type: 'get',
    url,
    success: (data) => {
      if (controller !== null) {
        toggleHeart(controller.likeHeartTarget, data.liked)
        controller.likesCountTarget.innerText = data.like_count
      }
    },
    error: (data) => { console.log(data) }
  })
}

export function openModal (target) {
  const targetElement = target.constructor.name === 'String' ? document.getElementById(target) : target
  targetElement.classList.toggle('is-active')
  if (targetElement.querySelector('[autofocus]')) {
    targetElement.querySelector('[autofocus]').focus()
  } else {
    targetElement.focus()
  }
  document.querySelector('html').classList.toggle('is-clipped')
}

export function listenClickAway (callback) {
  return document.addEventListener('click', () => {
    callback()
  })
}

export function visibleInViewport (element) {
  const bounding = element.getBoundingClientRect()
  const windowSize = (window.innerHeight || document.documentElement.clientHeight)
  return bounding.top > 64 && bounding.top < windowSize
}

export function scrollToTargetAdjusted (element, offset) {
  const elementPosition = element.getBoundingClientRect().top
  const offsetPosition = elementPosition - offset

  window.scrollBy({
    top: offsetPosition,
    behavior: 'smooth'
  })
}

export function suggest (suggestion) {
  const acceptSuggestion = `<span data-suggestion="${suggestion}" data-action="click->suggestion#applySuggestion"
                                    class="has-text-info is-pulled-right has-cursor-pointer mb-1">${window.I18n.accept_suggestion}</span>`
  return `${suggestion} ${acceptSuggestion}`
}
