import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['label', 'value']

  copy () {
    if (this.copied) { return false }
    this.valueTarget.select()
    this.valueTarget.setSelectionRange(0, 99999)
    document.execCommand('copy')
    this.labelTarget.insertAdjacentHTML('beforeend', '<span class="icon has-text-success"><i class="far fa-copy"></i></span>')
    this.copied = true
  }
}
