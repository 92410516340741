import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['publishButton']
  connect () {
    this.element.formController = this
  }

  propagateTrixEditorchange () {
    const trixEditors = this.element.querySelectorAll('trix-editor')
    const valid = Array.from(trixEditors).every((editor) => {
      return editor.dataset.valid === 'true'
    })

    this.publishButtonTarget.disabled = !valid
  }
}
