import { Controller } from '@hotwired/stimulus'
import { suggest } from '../utils'

export default class extends Controller {
  static targets = ['name', 'username', 'usernameSuggestion']

  connect () {
    this.currentUsername = ''
    this.regex = /^(?![_.])(?!.*[_.]{2})[a-z0-9._]+$/
  }

  validateUsername (event) {
    if (this.usernameTarget.value.length > 0) { this.usernameTarget.value = this.usernameTarget.value.toLowerCase() }
    if (this.usernameTarget.value === '' || this.regex.test(this.usernameTarget.value)) {
      this.currentUsername = this.usernameTarget.value
    } else {
      this.usernameTarget.value = this.currentUsername
    }
  }

  suggestUsername (event) {
    if (this.nameTarget.value.length > 0) {
      let suggestion = this.nameTarget.value.toLowerCase().split(' ').join('.').substr(0, 20)
      suggestion = suggestion.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      if (this.regex.test(suggestion)) {
        this.usernameSuggestionTarget.innerHTML = `${window.I18n.suggested_username} ${suggest(suggestion)}`
        this.usernameSuggestionTarget.classList.remove('is-hidden')
      } else {
        this.usernameSuggestionTarget.classList.add('is-hidden')
      }
    } else {
      this.usernameSuggestionTarget.classList.add('is-hidden')
    }
  }
}
