import { Controller } from '@hotwired/stimulus'
import { openModal } from '../utils'

export default class extends Controller {
  close (event) {
    event.currentTarget.closest('.modal').classList.toggle('is-active')
    document.querySelector('html').classList.toggle('is-clipped')
  }

  open (event) {
    const targetId = event.currentTarget.dataset.modalTarget
    openModal(targetId)
  }
}
