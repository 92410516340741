import TimeAgo from 'javascript-time-ago'
import es from 'javascript-time-ago/locale/es'
import en from 'javascript-time-ago/locale/en'

class LocalizedTimeAgo {
  loadTimeAgoLocale () {
    // eslint-disable-next-line no-undef
    switch (window.currentSimpleLocale) {
      case 'es':
        TimeAgo.addLocale(es)
        break
      case 'en':
        TimeAgo.addLocale(en)
        break
    }

    this.timeAgo = new TimeAgo(window.currentSimpleLocale)
    this.localesAdded = true
  }

  format (date) {
    if (!this.localesAdded) { this.loadTimeAgoLocale() }
    return this.timeAgo.format(parseInt(date), 'twitter')
  }
}

export default new LocalizedTimeAgo()
